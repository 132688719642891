import { AppProps } from 'next/app';
import { ReactElement, ReactNode, useMemo } from 'react';
import { NextPage } from 'next';
import { SessionProvider } from 'next-auth/react';
import 'styles/globals.css';
import { CartProvider } from 'lib/cart-context';
import { useState } from 'react';
import {
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { FormattedMessage, IntlProvider } from 'react-intl';
import { useRouter } from 'next/router';

import English from '../locales/compiled-locales/en.json';
import German from '../locales/compiled-locales/de.json';
import Matomo from 'components/Matomo';
import FBPixel from 'components/FBPixel';

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
  pageProps: {
    session: any;
  };
};

export default function App({
  Component,
  pageProps: { session, ...pageProps },
}: AppPropsWithLayout) {
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? ((page: ReactNode) => page);
  const [queryClient] = useState(() => new QueryClient());
  const { locale } = useRouter();
  const messages = useMemo(() => {
    switch (locale) {
      case 'de':
        return German;
      case 'en':
        return English;
      default:
        return German;
    }
  }, [locale]);

  return (
    <IntlProvider locale={locale} defaultLocale={'de'} messages={messages}>
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <SessionProvider session={session}>
            <Matomo />
            <FBPixel />
            <CartProvider>
              {getLayout(<Component {...pageProps} />)}
            </CartProvider>
          </SessionProvider>
        </Hydrate>
      </QueryClientProvider>
    </IntlProvider>
  );
}
